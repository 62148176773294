<template>
  <div class="section">
    <UndoConfirmButtons :confirm-enabled="true" @undo="undo" @confirm="createChapter" />
  </div>
</template>

<script>
import UndoConfirmButtons from "@/components/common/UndoConfirmButtons.vue";
import { CreateChapter } from "@/models/actions/operations.gql";

export default {
  name: "ChaptersAdministration",
  components: {
    UndoConfirmButtons
  },
  methods: {
    undo() {
      this.$log.info("just kidding");
    },
    createChapter() {
      this.$apollo.mutate({
        mutation: CreateChapter,
        variables: {
          descriptionJson: {
            type: "doc",
            content: [
              {
                type: "paragraph",
                content: [
                  {
                    text: "Welcome to This One's place!",
                    type: "text"
                  }
                ]
              }
            ]
          },
          externalUrl: `http://thisone.com`,
          lat: 50,
          lng: 50,
          title: `This One's`
        }
      });
    }
  }
};
</script>
